import './LearnMoreSuite.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';

import Aos from 'aos';
import EmailIcon from '@material-ui/icons/Email';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import ShapyaSpinner from '../../../images/shapya_spinner.gif';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const EmailTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField);

const recaptchareq = () => {
  const recaptchares = document.querySelector('#g-recaptcha-response');

  if (recaptchares) {
    recaptchares.setAttribute('required', 'required');
  }
};

const LearnMoreSuite = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  function sendEmail(e) {
    e.preventDefault();

    const learnMoreButtonSubmit = document.getElementById(
      'learnMoreButtonSubmit',
    );

    emailjs
      .sendForm(
        'default_service',
        'store_contact_form',
        e.target,
        'user_OWsnyBPupik9scReHvsc5',
      )
      .then(
        (result) => {
          console.log(result.text);

          setTimeout(function () {
            learnMoreButtonSubmit.setAttribute('disabled', 'true');
            learnMoreButtonSubmit.style.opacity = '0.5';
          }, 100);
          setTimeout(function () {
            document.getElementById('formloader').style.display = 'block';
          }, 100);
          setTimeout(function () {
            document.getElementById('linktm').click();
          }, 3500);
        },
        (error) => {
          console.log(error.text);
        },
      );
    e.target.reset();
  }

  return (
    <div className="shapyaMaxWidth" id="learnMoreSuite">
      <Grid className="learnMoreSuiteMainGrid">
        <Box className="learnMoreSuiteTitle">
          {t('indexbody.learnMoreSuite.title')}
        </Box>
        <Box className="learnMoreSuiteDescription">
          {t('indexbody.learnMoreSuite.description')}
        </Box>
        <form onSubmit={sendEmail}>
          <Grid container className="learnMoreSuiteInputButtonGrid">
            <EmailTextField
              placeholder={t('indexbody.learnMoreSuite.placeholder')}
              variant="outlined"
              color="secondary"
              type="email"
              name="learn_more_suite_email"
              required
              autoComplete="off"
              inputProps={{
                maxLength: 40,
                pattern:
                  '[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon className="learnMoreSuiteInputAdornment" />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              className="learnMoreSuiteButton"
              disableElevation
              type="submit"
              onClick={recaptchareq}
              id="learnMoreButtonSubmit"
            >
              {t('indexbody.learnMoreSuite.button')}
            </Button>
          </Grid>
          <Grid item className="captchaGrid" xs={12}>
            <Box align="center" mt={2}>
              <ReCAPTCHA
                sitekey="6Lc0WWwaAAAAADbTnntpfueo8PdvKyGpVMqiX4mG"
                onChange={onChange}
                hl="en"
              />
              <Box color="#fff" id="recaptchafield">
                <small>
                  <b>{t('form.rightside.recaptchamsg')}</b>
                </small>
              </Box>
            </Box>
            <Link component="a" href="/thanksMessage/" id="linktm"></Link>
            <Box align="center" mt={2} id="formloader">
              <img src={ShapyaSpinner} width="120" />
            </Box>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default LearnMoreSuite;
